import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const InfoSection = () => {
  return (
    <Container className="my-5">
      <Row>
        <Col md={4}>
          <Card className="text-center">
            <Card.Img variant="top" src="/images/kinhnghiem.jpg" alt="Giới Thiệu" />
            <Card.Body>
              <Card.Title>Giới Thiệu</Card.Title>
              <Card.Text>
                Trung tâm tiếng Anh Vitaminfun mang đến môi trường học tập chuyên nghiệp với đội ngũ giáo viên giàu kinh nghiệm và tận tâm.
              </Card.Text>
              <Button variant="primary" href="/gioi-thieu">Chi tiết</Button>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="text-center">
            <Card.Img variant="top" src="/images/khoahoc.jpg" alt="Khóa Học" />
            <Card.Body>
              <Card.Title>Khóa Học</Card.Title>
              <Card.Text>
                Khám phá các khóa học từ cơ bản đến nâng cao, tập trung vào các kỹ năng quan trọng như nghe, nói, đọc, viết và ngữ pháp.
              </Card.Text>
              <Button variant="primary" href="/khoa-hoc">Chi tiết</Button>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="text-center">
            <Card.Img variant="top" src="/images/dangki.jpg" alt="Đăng Ký Học" />
            <Card.Body>
              <Card.Title>Đăng Ký Học</Card.Title>
              <Card.Text>
                Đăng ký dễ dàng chỉ với vài bước, đội ngũ tư vấn viên luôn sẵn sàng hỗ trợ bạn trong suốt quá trình học tập.
              </Card.Text>
              <Button variant="primary" href="/dang-ky-hoc">Chi tiết</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InfoSection;
