import React from 'react';
import Banner from './Banner';
import InfoSection from './InfoSection';

const Home = () => {
  return (
    <div>
      <Banner />
      <InfoSection />      
    </div>
  );
};

export default Home;
