import React from 'react';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#f8f9fa', padding: '20px' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h5>Trung tâm tiếng Anh Vitaminfun</h5>
            <p>Địa chỉ: Lô 24, khu đấu giá TT2, phố Giang Biên, phường Giang Biên, quận Long Biên, TP. Hà Nội.</p>
            <p>Điện thoại: 0985737980</p>
            <p>Email: <a href="mailto:vitaminfunfun@gmail.com">vitaminfunfun@gmail.com</a></p>
          </div>
          <div className="col-md-6">
            <h5>Địa chỉ trên Google Map</h5>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.3560851246502!2d105.91336567591111!3d21.058435586757362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135a9ef6a199493%3A0xeb75f09c3cd390ae!2sVitaminfun%20Education!5e0!3m2!1sen!2s!4v1725419254284!5m2!1sen!2s"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Vitaminfun Location"
            ></iframe>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
