import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';  // Import Link từ react-router-dom

const Header = () => {
  return (
    <Navbar expand="lg" style={{ backgroundColor: 'white' }}>
      <Navbar.Brand as={Link} to="/">
        <img
          src="/images/logo_vtf.jpg"
          alt="Vitaminfun Logo"
          width="200"
          height="200"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/" style={{ color: 'blue' }}>Trang Chủ</Nav.Link>
          <Nav.Link as={Link} to="/gioi-thieu" style={{ color: 'blue' }}>Giới Thiệu</Nav.Link>
          <Nav.Link as={Link} to="/khoa-hoc" style={{ color: 'blue' }}>Khóa Học</Nav.Link>
          <Nav.Link as={Link} to="/dang-ky" style={{ color: 'blue' }}>Đăng Ký Học</Nav.Link>
          <Nav.Link href="#contact" style={{ color: 'blue' }}>Liên Hệ</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
