import React from 'react';
import { Container } from 'react-bootstrap';

const Banner = () => {
    return (
        <div className="p-5 text-white text-center" style={{ backgroundImage: 'url("/images/banner.jpg")', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '500px', margin: '0', padding: '0' }}>
            <Container>
                <h1>Chào mừng đến với Vitaminfun</h1>
                <p>Nâng cao kỹ năng tiếng Anh của bạn với những khóa học chất lượng</p>
            </Container>
        </div>
    );
};

export default Banner;
