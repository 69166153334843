import React from 'react';
import { Container, Image } from 'react-bootstrap';

const Introduction = () => {
  return (
    <div>
      <Container className="my-5">
        <h1>Trung Tâm Tiếng Anh Vitaminfun – Nơi Ươm Mầm Tương Lai Với Tiếng Anh</h1>

        <p><strong>Ra đời vào năm 2013</strong>, Trung tâm tiếng Anh Vitaminfun đã trở thành một địa chỉ tin cậy cho việc rèn luyện và phát triển kỹ năng tiếng Anh cho học sinh tại khu đô thị Việt Hưng, Long Biên, Hà Nội. Khi đó, ở khu vực này chưa có nhiều nơi cung cấp môi trường học tập tiếng Anh chất lượng cho trẻ em sau giờ học. <strong>Vitaminfun</strong> xuất hiện như một giải pháp nhằm mang lại cơ hội rèn luyện tiếng Anh trong không gian học tập hiệu quả và vui vẻ.</p>

        <h2>Đội Ngũ Lãnh Đạo Đầy Tâm Huyết</h2>

        <p>Trung tâm do <strong>thạc sỹ ngôn ngữ học Bạch Ánh Hồng</strong> đứng đầu. Cô Hồng không chỉ là giảng viên, trưởng khoa ngoại ngữ tại Học viện Chính sách và Phát triển thuộc Bộ Kế hoạch và Đầu tư, mà còn là một chuyên gia dịch thuật trong các hội thảo quốc tế, các dự án chuyển giao công nghệ từ nước ngoài về Việt Nam. Với kiến thức sâu rộng và kinh nghiệm phong phú, cô Hồng đã xây dựng một nền tảng vững chắc cho trung tâm, tạo điều kiện thuận lợi để học sinh phát triển toàn diện kỹ năng tiếng Anh.</p>
        
        <div className="text-center">
          <Image src="/images/cohong.jpg" alt="Thạc sỹ Bạch Ánh Hồng" fluid style={{ width: '500px' }} />
          <p className="text-center"><em>Thạc sỹ Bạch Ánh Hồng - Giám đốc trung tâm</em></p>
        </div>

        <h2>Phương Pháp Giảng Dạy Đa Dạng Và Hiệu Quả</h2>

        <p>Điểm nổi bật của Vitaminfun không chỉ nằm ở sự nhiệt tình của đội ngũ giáo viên mà còn ở <strong>phương pháp giảng dạy đa dạng</strong> mà cô Hồng đã áp dụng. Cô kết hợp giữa tính nghiêm túc và tạo hứng thú trong quá trình học tập, từ việc giảng dạy ngữ pháp cơ bản đến tổ chức các buổi <strong>dã ngoại thăm quan bảo tàng</strong> hay <strong>chương trình thiện nguyện</strong>. Mỗi hoạt động không chỉ giúp học sinh mở mang kiến thức mà còn lồng ghép với việc thực hành tiếng Anh một cách tự nhiên và hiệu quả.</p>

        <div className="text-center">
          <Image src="/images/didangoai.jpg" alt="Hoạt động dã ngoại của học sinh" fluid style={{ width: '500px' }} />
          <p className="text-center"><em>Hoạt động dã ngoại của học sinh Vitaminfun</em></p>
        </div>

        <p>Bên cạnh đó, cô còn sử dụng <strong>âm nhạc và thơ</strong> để tạo sự thích thú cho học sinh, giúp việc học trở nên gần gũi và dễ nhớ hơn. Những bài hát và câu thơ bằng tiếng Anh đã góp phần xây dựng nền tảng từ vựng và ngữ pháp vững chắc cho học sinh, đồng thời giúp các em cảm nhận tiếng Anh không chỉ là môn học mà còn là công cụ để khám phá thế giới.</p>

        <div className="text-center">
          <Image src="/images/dituthien1.jpg" alt="Hoạt động thiện nguyện của trung tâm" fluid style={{ width: '500px' }} />
          <p className="text-center"><em>Hoạt động thiện nguyện của học sinh Vitaminfun - Hình 1</em></p>
        </div>

        <div className="text-center">
          <Image src="/images/dituthien2.jpg" alt="Hoạt động thiện nguyện của trung tâm" fluid style={{ width: '500px' }} />
          <p className="text-center"><em>Hoạt động thiện nguyện của học sinh Vitaminfun - Hình 2</em></p>
        </div>

        <h2>Luyện Thi Cambridge Và Kết Quả Đáng Tự Hào</h2>

        <p>Trung tâm Vitaminfun tự hào khi theo sát bộ giáo trình quốc tế của <strong>Cambridge</strong>, cung cấp các khóa luyện thi chất lượng cao cho học sinh. Phương pháp giảng dạy bám sát chương trình chuẩn quốc tế, giúp học sinh đạt được thành tích cao trong các kỳ thi tiếng Anh, mở ra nhiều cơ hội phát triển trong tương lai.</p>

        <p>Nhiều học sinh của Vitaminfun đã đạt <strong>kết quả xuất sắc</strong> trong học tập và thi cử, không chỉ giúp các em có nền tảng tiếng Anh vững chắc mà còn mở ra cánh cửa hòa nhập quốc tế, cơ hội du học, và phát triển nghề nghiệp. Đối với cô Hồng, tiếng Anh không chỉ là ngôn ngữ mà còn là chìa khóa giúp các em khám phá những nền văn minh, văn hóa của nhân loại, cũng như phục vụ các nhu cầu cá nhân như du lịch và giao lưu quốc tế.</p>

        <h2>Kết Luận</h2>

        <p>Trung tâm tiếng Anh Vitaminfun không chỉ là nơi cung cấp kiến thức mà còn là môi trường phát triển toàn diện, nơi mà học sinh được trải nghiệm các phương pháp học tập thú vị và hiệu quả. Với sự dẫn dắt của cô Bạch Ánh Hồng, Vitaminfun đã và đang tiếp tục xây dựng những thế hệ học sinh tự tin với tiếng Anh, sẵn sàng đón nhận những cơ hội trong tương lai.</p>
      </Container>
    </div>
  );
};

export default Introduction;
