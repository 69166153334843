import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Import Routes
import Home from './components/Home';  // Import trang chủ
import Introduction from './components/Introduction';  // Import trang Giới Thiệu
import Registration from './components/Registration';  // Import trang Giới Thiệu
import Courses from './components/Courses';  // Trang khóa học
import Footer from './components/Footer';  // Import Footer
import Header from './components/Header';  // Import Header
import './App.css';

function App() {
  return (
    <div className="container-custom">

      <Router>
        <Header /> {/* Header hiển thị trên tất cả các trang */}

        <Routes>  {/* Dùng Routes thay cho Switch */}
          <Route exact path="/" element={<Home />} />  {/* Trang chủ */}
          <Route path="/gioi-thieu" element={<Introduction />} />  {/* Trang Giới Thiệu */}
          <Route path="/khoa-hoc" element={<Courses />} />  {/* Trang Giới Thiệu */}
          <Route path="/dang-ky" element={<Registration />} />  {/* Trang Giới Thiệu */}
          {/* Bạn có thể thêm các route khác ở đây */}
        </Routes>

        <Footer /> {/* Footer hiển thị trên tất cả các trang */}
      </Router>
    </div>
  );

}

export default App;
