import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const Courses = () => {
  return (
    <Container className="my-5">
      <h1>Khóa Học Tại Vitaminfun</h1>
      <Row>
        {/* Pre-Ielts */}
        <Col md={4}>
          <Card className="text-center">
            <Card.Img variant="top" src="/images/pre-ielts.jpg" alt="Pre-Ielts" />
            <Card.Body>
              <Card.Title>Pre-Ielts</Card.Title>
              <Card.Text>
                Khóa học dành cho học sinh lớp 9 ôn luyện thi ielts phục vụ tuyển sinh vào lớp 10 và đại học. Học sinh được tiếp cận phương pháp ôn luyện ielts hiệu quả với giáo viên Việt Nam và nước ngoài.
              </Card.Text>
              <Button variant="primary" href="/pre-ielts">Chi tiết</Button>
            </Card.Body>
          </Card>
        </Col>

        {/* Fun Teen B1 */}
        <Col md={4}>
          <Card className="text-center">
            <Card.Img variant="top" src="/images/fun-teen-b1.jpg" alt="Fun Teen B1" />
            <Card.Body>
              <Card.Title>Fun Teen B1</Card.Title>
              <Card.Text>
                Khóa học dành cho học sinh lớp 6, 7, 8 học ngữ pháp, các bài luyện trong bộ KET, PET của Cambridge.
              </Card.Text>
              <Button variant="primary" href="/fun-teen-b1">Chi tiết</Button>
            </Card.Body>
          </Card>
        </Col>

        {/* A2 */}
        <Col md={4}>
          <Card className="text-center">
            <Card.Img variant="top" src="/images/a2-course.jpg" alt="A2 Course" />
            <Card.Body>
              <Card.Title>A2</Card.Title>
              <Card.Text>
                Khóa học dành cho học sinh lớp 5, 6, 7 bổ sung kiến thức về ngữ pháp, giao tiếp cơ bản trình độ A2, các bài luyện tập trung vào Movers và Flyers.
              </Card.Text>
              <Button variant="primary" href="/a2-course">Chi tiết</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Courses;
