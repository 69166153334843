import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';

const Registration = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    dob: '',
    phone: '',
    email: '',
    school: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Container className="my-5">
      <h1>Chào mừng đến với Quy trình Tuyển sinh của Vitaminfun</h1>
      <p>
        Quy trình tuyển sinh của chúng tôi gồm các bước:
        <ul>
          <li>Tiếp nhận thông tin</li>
          <li>Đăng ký thông tin</li>
          <li>Nhân viên hẹn thời gian làm bài test</li>
          <li>Làm bài test từ 45 phút đến 02 giờ tùy trình độ</li>
          <li>Thông báo kết quả</li>
          <li>Xếp lớp</li>
          <li>Tư vấn học tập</li>
        </ul>
      </p>
      
      <h2>Đăng ký thông tin của bạn</h2>
      <Form>
        <Form.Group controlId="formFullName" className="mb-3">
          <Form.Label>Họ tên</Form.Label>
          <Form.Control 
            type="text" 
            name="fullName"
            placeholder="Nhập họ tên"
            value={formData.fullName}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formDob" className="mb-3">
          <Form.Label>Ngày tháng năm sinh</Form.Label>
          <Form.Control 
            type="date" 
            name="dob"
            value={formData.dob}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formPhone" className="mb-3">
          <Form.Label>Số điện thoại liên hệ</Form.Label>
          <Form.Control 
            type="tel" 
            name="phone"
            placeholder="Nhập số điện thoại"
            value={formData.phone}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>Email liên hệ</Form.Label>
          <Form.Control 
            type="email" 
            name="email"
            placeholder="Nhập email"
            value={formData.email}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formSchool" className="mb-3">
          <Form.Label>Tên trường đang học</Form.Label>
          <Form.Control 
            type="text" 
            name="school"
            placeholder="Nhập tên trường"
            value={formData.school}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formMessage" className="mb-3">
          <Form.Label>Một số nội dung muốn gửi tới Vitaminfun</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={4} 
            name="message"
            placeholder="Nhập nội dung"
            value={formData.message}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Gửi thông tin
        </Button>
      </Form>
    </Container>
  );
};

export default Registration;
